import Vue from "vue";
import App from "./App.vue";
import "vuetify/dist/vuetify.min.css";
import router from "./router";
import vuetify from "./plugins/vuetify";
import firebase from "firebase";
import VueSweetalert2 from "vue-sweetalert2";
import "firebase/auth";


import "sweetalert2/dist/sweetalert2.min.css";

const firebaseConfig = {
  apiKey: "AIzaSyA2eVgrTM7v0Kff-_4WIyaEkkwQiH0Vbps",
  authDomain: "kennis-en-veiligheid.firebaseapp.com",
  projectId: "kennis-en-veiligheid",
  storageBucket: "kennis-en-veiligheid.appspot.com",
  messagingSenderId: "100443185821",
  appId: "1:100443185821:web:ac903946892110a7a92a74"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

// eslint-disable-next-line no-unused-vars
let app;

firebase.auth().onAuthStateChanged(async function () {
  const user = firebase.auth().currentUser;
  if (user && router.currentRoute.path === '/') {
    router.push('/Dashboard');
  }

  new Vue({
    router,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
  app = true;
});



