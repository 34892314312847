import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase";
import axios from "axios";

Vue.use(VueRouter);
const isLoggedIn = () => firebase.auth().currentUser;

const routes = [
  {
    path: "/",
    name: "LoginScreen",
    component: () =>
      import(/* webpackChunkName: "Home" */ "../views/login/Inlogscherm.vue"),
    meta: {
      showHeader: true,
      title: "Kennis en veiligheid | Dashboard ",
    },
  },

  {
    path: "/Dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "@/layouts/AdminLayout.vue"),
    meta: { requiresAuth: true, accessRights: ["MAKELAAR"] },
    children: [
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkame: "Dashboard" */ "@/views/app/dashboard/Dashboard.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " Kennis en veilgheid | Dashboard ",
        },
      },

      {
        path: "/Klanten",
        name: "Klanten",
        component: () =>
          import(
            /* webpackChunkName: "Klanten" */ "@/views/app/klanten/Klanten.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Kennis en veiligheid | Klanten ",
        },
      },
      {
        path: "/Klantbekijken/:id",
        name: "Klantbekijken",
        component: () =>
          import(
            /* webpackChunkName: "Klanten" */ "@/views/app/klanten/Klantbekijken.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Kennis en veiligheid | Klant bekijken ",
        },
      },

      {
        path: "/Klanttoevoegen",
        name: "Klanttoevoegen",
        component: () =>
          import(
            /* webpackChunkName: "Klanten" */ "@/views/app/klanten/Klanttoevoegen.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Kennis en veiligheid | Klant toevoegen ",
        },
      },

      //Cursus

      {
        path: "/Cursussenbeheren",
        name: "Cursussenbeheren",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/cursussen/Cursussenbeheren.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Kennis en veiligheid | Cursussenbeheren ",
        },
      },

      {
        path: "/Cursustoevoegen",
        name: "Cursustoevoegen",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/cursussen/Cursustoevoegen.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "cursussen | Cursus toevoegen ",
        },
      },

      {
        path: "/Cursusbekijken/:id",
        name: "Cursusbekijken",
        component: () =>
          import(
            /* webpackChunkName: "cursus" */ "@/views/app/cursussen/Cursusbekijken.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Kennis en veiligheid | Cursusbekijken ",
        },
      },

      {
        path: "/Cursustoevoegen",
        name: "Cursustoevoegen",
        component: () =>
          import(
            /* webpackChunkName: "cursussen" */ "@/views/app/cursussen/Cursustoevoegen.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Kennis en veiligheid |  Cursustoevoegen ",
        },
      },

      {
        path: "/Cursusinplannen",
        name: "Cursusinplannen",
        component: () =>
          import(
            /* webpackChunkName: "cursussen" */ "@/views/app/cursussen/Cursusinplannen.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Kennis en veiligheid |  Cursusinplannen ",
        },
      },

      //

      {
        path: "/Bestellingen",
        name: "Bestellingen",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/webshop/Bestellingen.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Kennis en veiligheid | Webshopbestellingen ",
        },
      },

      {
        path: "/Bestellingbekijken/:id",
        name: "Bestellingbekijken",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/webshop/Bestellingbekijken.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Kennis en veiligheid | Bestelling ",
        },
      },
      //Instellingen
      {
        path: "/Folders",
        name: "Folders",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/instellingen/Folders.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Kennis en veiligheid | Folders ",
        },
      },

      {
        path: "/Mailinstellingen",
        name: "Mailinstellingen",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/instellingen/Mailinstellingen.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Kennis en veiligheid | Mailinstellingen ",
        },
      },

      {
        path: "/Mailtemplates",
        name: "Mailtemplates",
        component: () =>
          import(
            /* webpackChunkName: "Mailtemplate" */ "@/views/app/instellingen/mailtemplate/Mailtemplates.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Kennis en veiligheid | Mailtemplateinstellingen ",
        },
      },
      {
        path: "/Mailtemplatebekijken/:id",
        name: "Mailtemplatebekijken",
        component: () =>
          import(
            /* webpackChunkName: "Mailtemplate" */ "@/views/app/instellingen/mailtemplate/Mailtemplatebekijken.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Kennis en veiligheid | Mailtemplate bekijken ",
        },
      },

      {
        path: "/Mailtriggers",
        name: "Mailtriggers",
        component: () =>
          import(
            /* webpackChunkName: "Mailtriggers" */ "@/views/app/instellingen/Mailtriggers.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Kennis en veiligheid | Mailtriggerinstellen ",
        },
      },

      {
        path: "/Status",
        name: "Status",
        component: () =>
          import(
            /* webpackChunkName: "Status" */ "@/views/app/instellingen/Status.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Kennis en veiligheid | Status ",
        },
      },

      {
        path: "/Categorie",
        name: "Categorie",
        component: () =>
          import(
            /* webpackChunkName: "Categorie" */ "@/views/app/instellingen/Categorie.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Kennis en veiligheid | Categorie ",
        },
      },
      {
        path: "/Upload",
        name: "Upload",
        component: () =>
          import(
            /* webpackChunkName: "Categorie" */ "@/views/app/upload/Upload.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Kennis en veiligheid | Upload ",
        },
      },
      {
        path: "/Cronjobs",
        name: "Cronjobs",
        component: () =>
          import(
            /* webpackChunkName: "Mailtemplate" */ "@/views/app/instellingen/Cronjobs.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Kennis en veiligheid | Mailtemplate bekijken ",
        },
      },

      {
        path: "/Certificaat",
        name: "Certificaat",
        component: () =>
          import(
            /* webpackChunkName: "Categorie" */ "@/views/app/instellingen/Certificaat.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Kennis en veiligheid | Upload ",
        },
      },
//BLOG
      {
        path: "/BlogCategorie",
        name: "BlogCategorie",
        component: () =>
          import(
            /* webpackChunkName: "Categorie" */ "@/views/app/instellingen/BlogCategorie.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Kennis en veiligheid | Upload ",
        },
      },
      {
        path: "/Blogs",
        name: "Blogs",
        component: () =>
          import(
            /* webpackChunkName: "Categorie" */ "@/views/app/blog/Blogs.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Kennis en veiligheid | Upload ",
        },
      },
      {
        path: "/Bloginformatie/:id",
        name: "Bloginformatie",
        component: () =>
          import(
            /* webpackChunkName: "Bloginformatie" */ "@/views/app/blog/Bloginformatie.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Kennis en veiligheid | Upload ",
        },
      },
      {
        path: "/Blogtoevoegen",
        name: "Blogtoevoegen",
        component: () =>
          import(
            /* webpackChunkName: "Blogtoevoegen" */ "@/views/app/blog/Blogtoevoegen.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Kennis en veiligheid | Upload ",
        },
      },
    ],
  },

  {
    path: "*",
    name: "/",
    component: () =>
      import(
        /* webpackChunkName: "NotFound" */ "../views/login/Inlogscherm.vue"
      ),
    meta: { title: " Integratiepartners| Oeps pagina niet gevonden" },
  },
];

export async function setFirebaseToken() {
  const token = (await isLoggedIn())
    ? await firebase.auth().currentUser.getIdToken(true)
    : null;
  if (token) axios.defaults.headers.common["fireToken"] = token;
}

async function onAuthRequired(to, from, next) {
  document.title = to.meta.title || "k-env | k-env Nederland";
  if (!to.meta.accessRights && to.path !== "/") return next("/");
  await setFirebaseToken();
  if (to.meta.requiresAuth) {
    if (!(await isLoggedIn())) {
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    } else {
      const response = await axios.get(
        "https://server.k-env.nl/api/rest/v2/whoami"
      );
      const accessRight = response.data.accessRight;
      if (!to.meta.accessRights.includes(accessRight)) return next("/");
    }
  }
  next();
}

const router = new VueRouter({
  mode: "history",
  //Dit voor weer naar begin
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(onAuthRequired);
export default router;
