<template>
  <v-app>
    <v-main
      :style="$route.meta.showFooter === false ? 'padding-left: 64px' : ''"
    >
      <Header v-if="$route.meta.showFooter !== false" />
      <router-view />
    </v-main>
    <Footer v-if="$route.meta.showFooter !== false" />
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      deferredPrompt: null,
    };
  },
};
</script>

<style>
.pac-container {
  z-index: 2000 !important;
}
</style>

<style scoped lang="scss">
body {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  height: 100%;
}
// .main-content {
//   margin: 10px 0px;
// }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
